import React, { useState, useEffect } from "react"
import AddBtn from "components/pages/AddBtn"
import ProjectCard from "components/pages/ProjectCard"
import firebase from "../config/firebase"
import { message } from "antd"
import HeaderSecondary from "components/shared/HeaderSecondary"
import Footer from "components/shared/Footer"
import SidebarTwo from "components/shared/SidebarTwo"

const AdminProject = () => {
	const [title, setTitle] = useState("Projects")
	const [btnTitle, setBtnTitle] = useState("Project")
	const [data, setData] = useState([])
	const [value, setValue] = useState("")
	function handleChange(newValue) {
		setValue(newValue)
	}
	useEffect(() => {
		setData([])
		firebase
			.firestore()
			.collection("projects")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((element) => {
					var singleData = { data: element.data(), id: element.id }
					setData((prevState) => [...prevState, singleData])
				})
			})
			.then(() => {})
			.catch(() => {
				message.info("Error Occured! Can't Fetch the Record")
			})
	}, [value])
	return (
		<>
			<HeaderSecondary />
			<div className="row m-0 forFlex" style={{ minHeight: "90vh" }}>
				<div
					className={
						false ? "col-lg-2 col-md-3 col-sm-4 m-0 p-0 height-full" : "col-lg-2 col-md-3 col-sm-4 m-0 p-0 height-0"
					}
				>
					<SidebarTwo />
				</div>
				<div className="col-lg-10 col-md-9 col-sm-8 mt-4 p-0 bg-white" style={{ zIndex: "-100" }}>
					<div>
						<AddBtn title={title} btnTitle={btnTitle} recordId={data.id} value={value} onChange={handleChange} />
						{data.map((element) => {
							return (
								<ProjectCard
									projectTitle={element.data.projectTitle}
									projectCategory={element.data.projectCategory}
									projectDescription={element.data.projectDescription}
									projectTags={element.data.projectTags}
									projectTechnology={element.data.projectTechnology}
									recordId={element.id}
									value={value}
									onChange={handleChange}
								/>
							)
						})}
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default AdminProject
